export const USER_PREFERENCES = 'UserPreferences';
export const REQUEST_CANCEL_MESSAGE = 'CANCEL';

export const DATE_FORMAT = 'DD MMM YYYY';
export const TIME_FORMAT = 'h:mm A';
export const TOOLTIP_DATE_FORMAT = 'DD/MM/YYYY h:mm A';

export const SUBSCRIPTION_TYPE = {
  MONTHLY: 1,
  YEARLY: 2,
  LIFE_TIME: 3
};

export const SUBSCRIPTION_PLAN_STATUS = {
  ACTIVE: 1,
  CANCELED: 2,
  ENDED: 3,
  UNPAID: 4
};

export const SUBSCRIPTION_PLAN_STATUS_NAME = {
  1: 'ACTIVE',
  2: 'CANCELED',
  3: 'ENDED',
  4: 'UNPAID'
};
