import { combineReducers } from 'redux';
import authReducer from './authReducer';
import companyReducer from './companyReducer';
import messageReducer from './messageReducer';
import subscriptionReducer from './subscriptionReducer';
import userReducer from './userReducer';

const appReducer = combineReducers({
  auth: authReducer,
  message: messageReducer,
  user: userReducer,
  company: companyReducer,
  subscription: subscriptionReducer
});

export default appReducer;
