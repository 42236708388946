import axios from 'axios';
import jwt_decode from 'jwt-decode';
import UserPreferenceSingleton from './userPreferenceSingleton';

export const setupToken = () => {
  const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
  const { token } = userDetails || {};

  if (token) {
    const decoded = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp > currentTime) {
      setAuthToken(token);
      return token;
    }
  }
  return false; // if no token or expired token, return false
};

export const saveToken = (token) => {
  setAuthToken(token);
};

export const clearToken = () => {
  UserPreferenceSingleton.getInstance().clearStoredUserData();
  clearAuthToken();
};

// header methods
const setAuthToken = (token) => {
  try {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } catch (e) {
    console.log('Error while settup token', e);
  }
};

const clearAuthToken = () => {
  delete axios.defaults.headers.common['Authorization'];
};
