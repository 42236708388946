import { combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';

const companyLoaderReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_COMPANY_LOADER
});

const companyListReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_COMPANY_LIST
});

const companiesReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_USER_COMPANIES
});

const companyItemReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_COMPANY_ITEM
});

const companyReducer = combineReducers({
  companies: companiesReducer,
  loading: companyLoaderReducer,
  companyItem: companyItemReducer,
  list: companyListReducer
});

export default companyReducer;
