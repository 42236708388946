import { combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';

const userLoaderReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_USER_LOADER
});

const usersReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_USERS
});

const userReducer = combineReducers({
  users: usersReducer,
  loading: userLoaderReducer
});

export default userReducer;
