import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/feather.css';
import './assets/css/aiyyna.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initLogService } from './services/logService';

initLogService();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
