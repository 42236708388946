import { combineReducers } from 'redux';
import { createReducer as createReducerOrig } from '@reduxjs/toolkit';
import moment from 'moment';
import { createReducer } from '../helpers/reduxHelpers';

import * as Actions from '../actions/types';

const subscriptionLoaderReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_SUBSCRIPTION_LOADER
});

const companySubscriptionsReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_COMPANY_SUBSCRIPTIONS
});

const subscriptionsReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_SUBSCRIPTIONS
});

const subscriptionPlansReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_SUBSCRIPTION_PLANS
});

const initialSubscriptionState = {
  cid: '',
  start_date: new Date(),
  end_date: new Date(moment().add(1, 'months').calendar()),
  plan_name: '',
  plan_desc: '',
  status: '',
  amount: '',
  payment_mode: '',
  payment_ref: '',
  discount: 0,
  promo_code: '',
  user_count: 0
};
const subscriptionItemReducer = createReducerOrig(initialSubscriptionState, (builder) => {
  builder
    .addCase(Actions.SET_SUBSCRIPTION_ITEM, (state = initialSubscriptionState, action) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_SUBSCRIPTION_ITEM, (state, action) => {
      const subscription = { ...state };
      subscription[action.payload.propsName] = action.payload.value;
      return { ...subscription };
    });
});

const subscriptionReducer = combineReducers({
  companySubscriptions: companySubscriptionsReducer,
  loading: subscriptionLoaderReducer,
  list: subscriptionsReducer,
  plans: subscriptionPlansReducer,
  subscriptionItem: subscriptionItemReducer
});

export default subscriptionReducer;
