import { setCurrentUser } from '../actions/authActions';
import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import { setupToken } from './../helpers/authTokenHelpers';
import { setLogUser } from './logService';

export const appInit = () => (dispatch) => {
  const token = setupToken();
  if (token) {
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
    if (userDetails) {
      setLogUser(userDetails);
    }
    dispatch(setCurrentUser(userDetails));
  }
};

export const clearOrgReduxData = () => (dispatch) => {};
