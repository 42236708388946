// Auth
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_PROFILE = 'SET_CURRENT_USER_PROFILE';
export const UPDATE_CURRENT_USER_PROFILE_FIELD_VALUE = 'UPDATE_CURRENT_USER_PROFILE_FIELD_VALUE';
export const SET_AUTH_VALIDATION_ERRORS = 'SET_AUTH_VALIDATION_ERRORS';
export const SET_AUTH_LOADER = 'SET_AUTH_LOADER';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA';
export const SET_USER_ACCESS_DATA = 'SET_USER_ACCESS_DATA';
export const UPDATE_USER_ACCESS_DATA = 'UPDATE_USER_ACCESS_DATA';

//Message
export const SET_RES_SUCCESS_MSG = 'SET_RES_SUCCESS_MSG';
export const SET_RES_ERROR_MSG = 'SET_RES_ERROR_MSG';
export const SET_RES_WARNING_MSG = 'SET_RES_WARNING_MSG';
export const CLEAR_RES_MSG = 'CLEAR_RES_MSG';
export const SET_ITEM_ERROR_MSG = 'SET_ITEM_ERROR_MSG';
export const SET_ITEM_SUCESS_MSG = 'SET_ITEM_SUCESS_MSG';

//Users
export const SET_USER_LOADER = 'SET_USER_LOADER';
export const SET_USERS = 'SET_USERS';

//Company
export const SET_COMPANY_LOADER = 'SET_COMPANY_LOADER';
export const SET_COMPANY_LIST = 'SET_COMPANY_LIST';
export const SET_USER_COMPANIES = 'SET_USER_COMPANIES';
export const SET_COMPANY_ITEM = 'SET_COMPANY_ITEM';

//Subscriptions
export const SET_SUBSCRIPTION_LOADER = 'SET_SUBSCRIPTION_LOADER';
export const SET_COMPANY_SUBSCRIPTIONS = 'SET_COMPANY_SUBSCRIPTIONS';
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const SET_SUBSCRIPTION_PLANS = 'SET_SUBSCRIPTION_PLANS';
export const SET_SUBSCRIPTION_ITEM = 'SET_SUBSCRIPTION_ITEM';
export const UPDATE_SUBSCRIPTION_ITEM = 'UPDATE_SUBSCRIPTION_ITEM';
