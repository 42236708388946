import { isDev } from '../helpers/common';
import { REACT_APP_SENTRY_KEY } from '../global/environment';

/**
 * @desc init log system
 */
export const initLogService = () => {
  try {
    if (isDev()) return;

    if (!REACT_APP_SENTRY_KEY) {
      console.log('REACT_APP_SENTRY_KEY is not found');
      return;
    }
  } catch (e) {
    console.error(e);
  }
};

/**
 * @desc set userid and email for error log
 */
export const setLogUser = (user) => {
  try {
    if (isDev()) return;

    if (user) {
    }
  } catch (e) {
    console.log(e);
  }
};
