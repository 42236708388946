import React from 'react';
import styled from 'styled-components';
import Loading from '../../assets/images/svg/gear-loader.svg';

const MainWrapper = styled.div`
  z-index: 999;
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
`;
const Span = styled.span`
  background-image: url(${Loading});
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  transform: translate(-50%, -50%);
`;

const AppLoading = () => {
  return (
    <MainWrapper>
      <Span></Span>
    </MainWrapper>
  );
};

export default AppLoading;
