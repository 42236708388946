import { lazy } from 'react';
import { ROUTE } from '../global/routeConst';
const Home = lazy(() => import('../pages/Home'));
const Login = lazy(() => import('../pages/Membership/Login'));
const AccessDenied = lazy(() => import('../pages/AccessDenied'));

/**
 * Routes Array
 * Same properties should match for all attributes
 */
const routes = [
  {
    path: ROUTE.LOGIN,
    exact: true,
    component: Login,
    private: false
  },
  {
    path: ROUTE.ACCESS_DENIED,
    exact: true,
    component: AccessDenied
  },
  {
    path: ROUTE.MAIN,
    component: Home,
    private: true
  }
];

export default routes;
